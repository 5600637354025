import { useEffect, useState } from "react"

const useRender = () => {
  const [renderComponent, setRenderComponent] = useState(false)
  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  }, [])
  return {
    renderComponent: renderComponent,
  }
}

export default useRender
