import React, { useEffect, useState } from "react";


const OneTrust = (props) => {

    useEffect(() => {
        const script_one = document.createElement('script');

        script_one.src = "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
        script_one.defer = true;
        //script_one.async = true;
        script_one.setAttribute("data-domain-script", "951cfc32-00de-4099-b805-86eebc1844d1-test")

        document.body.appendChild(script_one);

    }, [])

    return (
        <></>
    )
}

export default OneTrust